/* eslint-disable max-len */
import { createTheme } from '@mui/material';
import { colors } from './colors.styles';
import { MuiCssBaseline } from './font';
import { tableStyles } from './table.styles';
import { formControlStyles } from './_form-controls.styles';

export const theme = createTheme({
  // shadows: [
  //   'none',
  //   '0px 0px 0px rgba(0, 0, 0, 0.25)',
  //   '0px 0px 0px rgba(0, 0, 0, 0.25)',
  //   '0px 0px 0px rgba(0, 0, 0, 0.25)',
  //   '0px 0px 0px rgba(0, 0, 0, 0.25)',
  //   ...Array(20).fill('none'),
  // ],
  palette: colors,
  typography: {
    fontFamily: 'fontRegular, Roboto',
    caption: {
      color: colors.text.caption,
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiCssBaseline,
    ...tableStyles,
    ...formControlStyles,
    MuiCard: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          border: '1px solid #EAEAEA',
          borderRadius: '10px',
          boxShadow: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          minHeight: '79px',
          boxShadow: 'none',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: 'fontSemiBold',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 20,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});
