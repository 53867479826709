import React, { useMemo, useState } from 'react';

export const TutorTeamContext = React.createContext({
  selectedRole: '',
  selectedLead: null,
  setSelectedLead: () => {},
  setSelectedRole: () => {},
});

export function TutorTeamProvider(props) {
  const [selectedRole, setSelectedRole] = useState();
  const [selectedLead, setSelectedLead] = useState();

  const values = useMemo(
    () => ({ selectedRole, setSelectedRole, selectedLead, setSelectedLead }),
    [selectedRole, selectedLead]
  );
  return <TutorTeamContext.Provider value={values}>{props.children}</TutorTeamContext.Provider>;
}
