import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export default function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  const handleClear = () => {
    setValue('');
    onChange('');
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <FormControl variant="standard" component="form">
      <OutlinedInput
        size="small"
        sx={{ mx: 2, flex: 1 }}
        {...props}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        endAdornment={
          <InputAdornment position="end">
            {value ? (
              <IconButton sx={{ p: 0, opacity: 0.3 }} aria-label="clear" onClick={handleClear}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton sx={{ p: 0, opacity: 0.3 }} aria-label="search">
                <SearchIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
