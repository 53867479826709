import SessionHistoryIcon from '../assets/icons/SessionHistoryIcon';
// import SessionHistoryMenu from '../assets/icons/SessionHistoryMenu';
import CommentIcon from '../assets/icons/nav/CommentIcon';
import DashboardIcon from '../assets/icons/nav/DashboardIcon';
// import DotAnalysisIcon from '../assets/icons/nav/DotAnalysisIcon';
import ManagementIcon from '../assets/icons/nav/ManagementIcon';
import ReportIcon from '../assets/icons/nav/ReportIcon';

import NotificationIcon from '../assets/icons/nav/NotificationIcon';
import OngoingSessionIcon from '../assets/icons/nav/OngoingSessionIcon';
// import ScheduleCalIcon from '../assets/icons/nav/ScheduleCalIcon';
import TutorAvailabilityIcon from '../assets/icons/nav/TutorAvailabilityIcon';
import UpcomingSessionIcon from '../assets/icons/nav/UpcomingSessionIcon';
import { PERMISSIONS } from '../shared/constants/constants';
import {
  COMMENTS_PATH,
  DASHBOARD_PATH,
  // DOT_ANALYSIS_PATH,
  MANAGEMENTS_PATH,
  NOTIFICATIONS_PATH,
  ONGOING_SESSION_PATH,
  REPORTS_PATH,
  // SCHEDULE_CALENDAR_PATH,
  TUTOR_AVAILABILITY_PATH,
  UPCOMING_SESSION_PATH,
  SESSION_HISTORY_PATH,
} from './routes';

export const nav = [
  {
    title: 'Dashboard',
    icon: DashboardIcon,
    path: DASHBOARD_PATH,
    permission: [],
    countKey: '',
    exact: true,
  },
  {
    title: 'Notifications',
    icon: NotificationIcon,
    path: NOTIFICATIONS_PATH,
    permission: [PERMISSIONS.NOTIFICATIONS],
    countKey: 'notification',
  },
  {
    title: 'Ongoing Sessions',
    icon: OngoingSessionIcon,
    path: ONGOING_SESSION_PATH,
    permission: [PERMISSIONS.ONGOING_SESSIONS],
    countKey: 'ongoingSession',
  },
  {
    title: 'Upcoming Sessions',
    icon: UpcomingSessionIcon,
    path: UPCOMING_SESSION_PATH,
    permission: [PERMISSIONS.UPCOMING_SESSIONS],
    countKey: 'upcomingSession',
  },
  {
    title: 'Tutor Availability',
    icon: TutorAvailabilityIcon,
    path: TUTOR_AVAILABILITY_PATH,
    permission: [PERMISSIONS.TUTOR_AVAILABILITY],
    countKey: '',
  },
  // {
  //   title: 'DOT Analysis',
  //   icon: DotAnalysisIcon,
  //   path: DOT_ANALYSIS_PATH,
  //   permission: [PERMISSIONS.DOT_ANALYSIS],
  //   countKey: '',
  // },
  {
    title: 'Comments',
    icon: CommentIcon,
    path: COMMENTS_PATH,
    permission: [],
    countKey: '',
  },
  {
    title: 'Session History',
    icon: SessionHistoryIcon,
    path: SESSION_HISTORY_PATH,
    permission: [PERMISSIONS.SESSION_HISTORY],
    countKey: '',
  },
  // {
  //   title: 'Schedule Calendar',
  //   icon: ScheduleCalIcon,
  //   path: SCHEDULE_CALENDAR_PATH,
  //   permission: [PERMISSIONS.SCHEDULE_CALENDER],
  //   countKey: '',
  // },
  {
    title: 'Management',
    icon: ManagementIcon,
    path: MANAGEMENTS_PATH,
    permission: [PERMISSIONS.MANAGEMENT],
    countKey: '',
  },
  {
    title: 'Reports',
    icon: ReportIcon,
    path: REPORTS_PATH,
    permission: [],
    countKey: '',
  },
];
