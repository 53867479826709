import { colors } from '../../theme/colors.styles';

export const dashStyle = {
  tableCellRoot: {
    padding: '5px',
    height: '35px',
    borderBottom: 0,
    fontSize: ' 0.75rem',
    color: colors.text.primary,
    fontFamily: 'fontMedium',
  },
  tableCellHead: {
    padding: '5px',
    height: '35px',
    color: '#000000',
    borderBottom: 0,
    fontSize: ' 0.82rem',
    fontFamily: 'fontSemiBold',
  },
};
