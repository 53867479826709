import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../redux/slices/auth.slice';
import { LOGIN_PATH } from '../../layout/routes';
import { emptySplitApi } from '../../redux/slices/api/v1/base';

export const useAuthCheck = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(getToken);

  useEffect(() => {
    if (!token) {
      dispatch(emptySplitApi.util.resetApiState());
      navigate(LOGIN_PATH);
    }
  }, [token]);

  return { token };
};
