import { createSlice } from '@reduxjs/toolkit';
import { emptySplitApi } from './api/v1/base';

const initialState = {
  details: {
    user: {},
    roles: [],
    permissions: [],
  },
};
export const currentUser = createSlice({
  name: 'currentUser',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      emptySplitApi.endpoints.getCurrentUser.matchFulfilled,
      (state, { payload }) => {
        state.details = payload;
      }
    );
  },
});

export const { setUserDetails } = currentUser.actions;
export const getUserDetails = (state) => state[currentUser.name].details.user;
export const getUserRoles = (state) => state[currentUser.name].details.roles;
export const getUserPermissions = (state) => state[currentUser.name].details.permissions;
