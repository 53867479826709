import PropTypes from 'prop-types';
import { useAuthority } from './useAuthority';

function HasAuthority({ children, roles = [], permissions = [] }) {
  const { hasAuth } = useAuthority();
  return hasAuth(roles, permissions) ? children : null;
}

HasAuthority.propTypes = {
  roles: PropTypes.array,
  permissions: PropTypes.array,
};

export default HasAuthority;
