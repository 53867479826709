/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';

import React from 'react';

function IconBadge({ color = ['#770000', '#3C3C3C'], ...props }) {
  return (
    <SvgIcon {...props}>
      <ellipse cx="9" cy="9.375" rx="9" ry="8.4375" fill={color[1]} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0335 17.8124H8.9665C8.97766 17.8125 8.98883 17.8125 9 17.8125C9.01117 17.8125 9.02234 17.8125 9.0335 17.8124ZM8.57141 17.8031C3.79986 17.5934 0 13.9002 0 9.375C0 4.7151 4.02944 0.9375 9 0.9375C13.8269 0.9375 17.7662 4.49985 17.99 8.97316H8.57141V17.8031ZM18 9.35834C18 9.36389 18 9.36945 18 9.375C18 9.38055 18 9.38611 18 9.39166V9.35834Z"
        fill={color[0]}
      />
    </SvgIcon>
  );
}

export default IconBadge;
