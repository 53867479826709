import { isRejectedWithValue } from '@reduxjs/toolkit';
import { setToken } from './slices/auth.slice';

export class AuthorizationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthorizationError';
  }
}

export const authenticationMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      if (action.payload.status === 401) {
        dispatch(setToken(null));
      }
    }
    return next(action);
  };
