/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function TutorAvailabilityIcon(props) {
  return (
    <SvgIcon
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.89078 8.10833H4.07161C4.02809 7.71908 4.04581 7.32542 4.12411 6.94166C3.90161 6.98426 3.67164 6.96402 3.45999 6.88321C3.24835 6.8024 3.06341 6.66422 2.92591 6.48417C2.78842 6.30412 2.70381 6.08932 2.68158 5.86387C2.65935 5.63841 2.70038 5.41123 2.80007 5.20779C2.89975 5.00435 3.05415 4.83272 3.24593 4.71213C3.43772 4.59155 3.65931 4.52678 3.88585 4.52511C4.11239 4.52343 4.33492 4.58491 4.52847 4.70265C4.72202 4.82039 4.87893 4.98972 4.98162 5.19166C5.24167 4.89171 5.54669 4.63393 5.88578 4.42749C5.65949 4.08178 5.34729 3.80077 4.97975 3.61196C4.61222 3.42316 4.20195 3.33305 3.78913 3.35044C3.3763 3.36784 2.97507 3.49215 2.62473 3.71121C2.27438 3.93026 1.98693 4.23655 1.79052 4.60007C1.59411 4.9636 1.49548 5.3719 1.50429 5.785C1.51309 6.1981 1.62903 6.60183 1.84075 6.95665C2.05247 7.31148 2.35271 7.60524 2.71207 7.80917C3.07143 8.0131 3.47759 8.1202 3.89078 8.11999V8.10833Z" />
      <path d="M5.005 10.1908C4.67434 9.81615 4.42064 9.38009 4.25833 8.90747H3.89083C3.19827 8.88244 2.50796 9.00001 1.86273 9.25289C1.2175 9.50576 0.63113 9.88854 0.14 10.3775L0 10.5408V14.6241H1.16667V10.99C1.93125 10.3584 2.89957 10.0266 3.89083 10.0566C4.26624 10.0569 4.64027 10.1019 5.005 10.1908Z" />
      <path d="M15.7091 10.3542C15.2246 9.8699 14.6459 9.49009 14.0088 9.23826C13.3717 8.98643 12.6897 8.86791 12.005 8.89003C11.8183 8.89003 11.655 8.89004 11.48 8.92504C11.309 9.41131 11.0411 9.85782 10.6925 10.2375C11.119 10.1144 11.561 10.0534 12.005 10.0567C12.9893 10.0245 13.9516 10.3522 14.7116 10.9784V14.6359H15.8783V10.5175L15.7091 10.3542Z" />
      <path d="M7.83998 10.2842C8.3557 10.2842 8.85983 10.1312 9.28863 9.84471C9.71743 9.5582 10.0516 9.15096 10.249 8.6745C10.4464 8.19804 10.498 7.67376 10.3974 7.16796C10.2968 6.66215 10.0484 6.19754 9.68376 5.83287C9.3191 5.46821 8.85449 5.21987 8.34868 5.11926C7.84288 5.01865 7.31859 5.07028 6.84214 5.26764C6.36568 5.46499 5.95844 5.7992 5.67193 6.22801C5.38541 6.65681 5.23248 7.16094 5.23248 7.67665C5.23248 8.36821 5.5072 9.03143 5.9962 9.52043C6.4852 10.0094 7.14843 10.2842 7.83998 10.2842ZM7.83998 6.24165C8.12495 6.24165 8.40352 6.32616 8.64047 6.48448C8.87741 6.6428 9.06209 6.86783 9.17114 7.1311C9.28019 7.39438 9.30873 7.68409 9.25313 7.96358C9.19754 8.24307 9.06031 8.49981 8.85881 8.70131C8.6573 8.90281 8.40057 9.04004 8.12108 9.09564C7.84158 9.15123 7.55188 9.1227 7.2886 9.01364C7.02532 8.90459 6.80029 8.71992 6.64197 8.48297C6.48365 8.24603 6.39915 7.96746 6.39915 7.68249C6.39915 7.30035 6.55095 6.93387 6.82116 6.66366C7.09137 6.39346 7.45785 6.24165 7.83998 6.24165Z" />
      <path d="M7.97997 11.1183C7.21749 11.0914 6.45746 11.2192 5.74566 11.4939C5.03387 11.7686 4.38505 12.1845 3.83831 12.7167L3.69247 12.88V17.1558C3.69474 17.2766 3.72088 17.3957 3.76939 17.5064C3.81789 17.617 3.88781 17.717 3.9751 17.8005C4.0624 17.8839 4.16535 17.9494 4.27802 17.9929C4.3907 18.0364 4.51088 18.0573 4.63164 18.0542H11.3225C11.4432 18.0573 11.5634 18.0364 11.6761 17.9929C11.7888 17.9494 11.8917 17.8839 11.979 17.8005C12.0663 17.717 12.1362 17.617 12.1847 17.5064C12.2332 17.3957 12.2594 17.2766 12.2616 17.1558V12.8683L12.1158 12.705C11.571 12.1726 10.923 11.7572 10.2118 11.4844C9.50059 11.2115 8.7411 11.087 7.97997 11.1183ZM11.0891 16.8817H4.84747V13.3467C5.72911 12.626 6.84184 12.2489 7.97997 12.285C9.10931 12.247 10.2141 12.6201 11.0891 13.335V16.8817Z" />
      <path d="M12.8042 6.60919C12.6447 6.76084 12.4457 6.86432 12.2299 6.90767C12.0142 6.95102 11.7906 6.93248 11.585 6.85419C11.6427 7.11865 11.672 7.38851 11.6725 7.65919C11.6793 7.79133 11.6793 7.92372 11.6725 8.05586C11.7823 8.07546 11.8935 8.08716 12.005 8.09086C12.3814 8.08957 12.7522 7.99902 13.0868 7.82663C13.4214 7.65424 13.7104 7.40494 13.93 7.09919C13.5334 6.98996 13.1544 6.82498 12.8042 6.60919Z" />
      <path d="M10.8091 3.65167C10.4575 3.85544 10.1626 4.14428 9.95163 4.49167C10.2883 4.71557 10.5875 4.99118 10.8383 5.30834C10.9116 5.12116 11.0298 4.95488 11.1825 4.82417C11.003 4.45305 10.8773 4.05825 10.8091 3.65167Z" />
      <path d="M15.1141 5.83333C16.7249 5.83333 18.0308 4.5275 18.0308 2.91667C18.0308 1.30584 16.7249 0 15.1141 0C13.5033 0 12.1974 1.30584 12.1974 2.91667C12.1974 4.5275 13.5033 5.83333 15.1141 5.83333Z" />
    </SvgIcon>
  );
}

export default TutorAvailabilityIcon;
