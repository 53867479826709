/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function ViewIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11 11C12.6569 11 14 9.65685 14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65685 9.34315 11 11 11Z" />
      <path d="M21.9563 7.75273C21.0938 5.54011 19.5964 3.62663 17.6492 2.24879C15.702 0.87095 13.3904 0.089144 11 0C8.60964 0.089144 6.29802 0.87095 4.35083 2.24879C2.40363 3.62663 0.906232 5.54011 0.0436893 7.75273C-0.0145631 7.91251 -0.0145631 8.08749 0.0436893 8.24727C0.906232 10.4599 2.40363 12.3734 4.35083 13.7512C6.29802 15.1291 8.60964 15.9109 11 16C13.3904 15.9109 15.702 15.1291 17.6492 13.7512C19.5964 12.3734 21.0938 10.4599 21.9563 8.24727C22.0146 8.08749 22.0146 7.91251 21.9563 7.75273ZM11 12.7273C10.0572 12.7273 9.1356 12.45 8.35171 11.9306C7.56781 11.4111 6.95684 10.6728 6.59605 9.80905C6.23526 8.94525 6.14086 7.99476 6.32479 7.07775C6.50872 6.16075 6.96271 5.31843 7.62936 4.65731C8.29601 3.99619 9.14537 3.54596 10.07 3.36356C10.9947 3.18116 11.9532 3.27477 12.8242 3.63257C13.6952 3.99037 14.4397 4.59627 14.9635 5.37367C15.4872 6.15106 15.7668 7.06503 15.7668 8C15.7649 9.25316 15.262 10.4544 14.3685 11.3406C13.475 12.2267 12.2636 12.7253 11 12.7273Z" />
    </SvgIcon>
  );
}

export default ViewIcon;
