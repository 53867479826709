/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function NotificationIcon(props) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.72 8.32V14.72C14.72 15.0595 14.5851 15.385 14.3451 15.6251C14.105 15.8651 13.7795 16 13.44 16H1.28C0.940523 16 0.61495 15.8651 0.374903 15.6251C0.134857 15.385 0 15.0595 0 14.72V2.56C0 2.22052 0.134857 1.89495 0.374903 1.6549C0.61495 1.41486 0.940523 1.28 1.28 1.28H7.68C7.84974 1.28 8.01253 1.34743 8.13255 1.46745C8.25257 1.58748 8.32 1.75026 8.32 1.92C8.32 2.08974 8.25257 2.25252 8.13255 2.37255C8.01253 2.49257 7.84974 2.56 7.68 2.56H1.28V14.72H13.44V8.32C13.44 8.15026 13.5074 7.98747 13.6275 7.86745C13.7475 7.74743 13.9103 7.68 14.08 7.68C14.2497 7.68 14.4125 7.74743 14.5325 7.86745C14.6526 7.98747 14.72 8.15026 14.72 8.32ZM16 2.88C16 3.44961 15.8311 4.00643 15.5146 4.48004C15.1982 4.95366 14.7484 5.32279 14.2221 5.54077C13.6959 5.75875 13.1168 5.81579 12.5581 5.70466C11.9995 5.59354 11.4863 5.31924 11.0835 4.91647C10.6808 4.51369 10.4065 4.00053 10.2953 3.44186C10.1842 2.88319 10.2412 2.30412 10.4592 1.77787C10.6772 1.25162 11.0463 0.801826 11.52 0.485368C11.9936 0.168909 12.5504 0 13.12 0C13.8838 0 14.6164 0.303428 15.1565 0.843532C15.6966 1.38364 16 2.11618 16 2.88ZM14.72 2.88C14.72 2.56355 14.6262 2.25421 14.4504 1.99109C14.2745 1.72797 14.0247 1.52289 13.7323 1.40179C13.4399 1.28069 13.1182 1.24901 12.8079 1.31074C12.4975 1.37248 12.2124 1.52487 11.9886 1.74863C11.7649 1.97239 11.6125 2.25749 11.5507 2.56786C11.489 2.87823 11.5207 3.19993 11.6418 3.49229C11.7629 3.78466 11.968 4.03454 12.2311 4.21035C12.4942 4.38616 12.8035 4.48 13.12 4.48C13.5437 4.4779 13.9494 4.30865 14.249 4.00905C14.5487 3.70944 14.7179 3.3037 14.72 2.88Z" />
    </SvgIcon>
  );
}

export default NotificationIcon;
