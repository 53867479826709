/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function OngoingSessionIcon(props) {
  return (
    <SvgIcon
      width="17"
      height="17"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.51812 1.019C8.34675 1.00635 8.17497 1.00001 8.00312 1V0C8.19966 9.58276e-05 8.39613 0.00743414 8.59212 0.022L8.51812 1.019ZM10.5221 1.469C10.2016 1.3453 9.87235 1.24537 9.53712 1.17L9.75612 0.194C10.1391 0.28 10.5161 0.394 10.8821 0.536L10.5221 1.469ZM11.8921 2.179C11.7491 2.08365 11.6027 1.99359 11.4531 1.909L11.9461 1.039C12.288 1.23274 12.6152 1.45132 12.9251 1.693L12.3101 2.482C12.1745 2.37623 12.0351 2.2755 11.8921 2.18V2.179ZM13.7261 3.969C13.5283 3.68798 13.3101 3.42192 13.0731 3.173L13.7971 2.483C14.0671 2.768 14.3171 3.073 14.5441 3.393L13.7261 3.969ZM14.4701 5.321C14.4045 5.16246 14.3331 5.00636 14.2561 4.853L15.1491 4.403C15.3257 4.75409 15.4761 5.11774 15.5991 5.491L14.6491 5.804C14.5954 5.64087 14.5357 5.47976 14.4701 5.321ZM15.0001 7.828C14.992 7.48434 14.9586 7.14174 14.9001 6.803L15.8851 6.633C15.9521 7.019 15.9911 7.411 16.0011 7.803L15.0011 7.828H15.0001ZM14.8691 9.366C14.9021 9.196 14.9291 9.027 14.9501 8.856L15.9431 8.979C15.8951 9.36915 15.8182 9.75521 15.7131 10.134L14.7491 9.867C14.7951 9.702 14.8351 9.535 14.8691 9.366ZM13.9171 11.745C14.1011 11.455 14.2631 11.151 14.4031 10.837L15.3171 11.242C15.1571 11.602 14.9721 11.948 14.7621 12.28L13.9171 11.745ZM12.9531 12.95C13.0751 12.828 13.1921 12.702 13.3031 12.572L14.0611 13.225C13.9328 13.3738 13.799 13.5179 13.6601 13.657L12.9531 12.95Z" />
      <path d="M8.00312 1C6.852 1.00008 5.71867 1.28405 4.7035 1.82674C3.68833 2.36943 2.82267 3.1541 2.18318 4.11125C1.54369 5.0684 1.15012 6.16848 1.03732 7.31406C0.924518 8.45964 1.09597 9.61536 1.5365 10.6789C1.97703 11.7423 2.67303 12.6808 3.56285 13.4111C4.45267 14.1413 5.50886 14.6409 6.63786 14.8655C7.76686 15.0901 8.93382 15.0328 10.0354 14.6987C11.1369 14.3645 12.1391 13.7639 12.9531 12.95L13.6601 13.657C12.7299 14.5878 11.5844 15.2747 10.3252 15.657C9.06595 16.0393 7.73189 16.105 6.44119 15.8485C5.15048 15.5919 3.94298 15.021 2.92567 14.1862C1.90836 13.3515 1.11266 12.2787 0.609058 11.0629C0.105457 9.84711 -0.0904899 8.52588 0.0385779 7.21627C0.167646 5.90666 0.617743 4.6491 1.34899 3.55502C2.08024 2.46094 3.07006 1.56411 4.23075 0.944003C5.39144 0.323895 6.68717 -0.000348732 8.00312 2.81458e-07V1Z" />
      <path d="M7.50312 3C7.63573 3 7.76291 3.05268 7.85668 3.14645C7.95045 3.24021 8.00312 3.36739 8.00312 3.5V8.71L11.2511 10.566C11.3629 10.6334 11.4439 10.7419 11.4768 10.8683C11.5096 10.9946 11.4917 11.1288 11.427 11.2422C11.3622 11.3556 11.2557 11.4391 11.1301 11.4749C11.0046 11.5108 10.87 11.4961 10.7551 11.434L7.25512 9.434C7.1786 9.39029 7.11498 9.32712 7.07073 9.25091C7.02648 9.17469 7.00315 9.08813 7.00312 9V3.5C7.00312 3.36739 7.0558 3.24021 7.14957 3.14645C7.24334 3.05268 7.37052 3 7.50312 3Z" />
    </SvgIcon>
  );
}

export default OngoingSessionIcon;
