/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function ReportIcon(props) {
  return (
    <SvgIcon
      width="17"
      height="17"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M355.9 128h52L345 67.2v51.3c0 5 5.1 9.5 10.9 9.5z" />
      <path
        fill="none"
        d="M355.9 168c-28.1 0-50.9-22.2-50.9-49.6V40.7l-216.6-.6c-5.5 0-10.4 4.7-10.4 10v411.8c0 5.2 5.3 10.2 10.9 10.2h339c5.7 0 9.1-5.2 9.1-10.2V168h-81.1zM320 247c11 0 20 9 20 20s-9 20-20 20H145c-11 0-20-9-20-20s9-20 20-20h175zm-176-80h116c11 0 20 9 20 20s-9 20-20 20H144c-11 0-20-9-20-20s9-20 20-20zm226 200H145c-11 0-20-9-20-20s9-20 20-20h225c11 0 20 9 20 20s-9 20-20 20z"
      />
      <path
        fill={props.color}
        d="M467.2 129.8 345.5 12C338.1 4.8 328 .7 317.6.7L88.4.1C60.6.1 38 22.5 38 50.1v411.8C38 489 61.3 512 88.9 512h339c27.1 0 49.1-22.5 49.1-50.2V153.7c0-9.3-3.5-17.8-9.8-23.9zM345 67.2l62.9 60.8h-52c-5.8 0-10.9-4.5-10.9-9.6V67.2zM427.9 472h-339c-5.6 0-10.9-4.9-10.9-10.2V50.1c0-5.3 4.9-10 10.4-10l216.6.6v77.7c0 27.3 22.8 49.6 50.9 49.6H437v293.8c0 5-3.4 10.2-9.1 10.2z"
      />
      <path
        fill={props.color}
        d="M125 267c0 11 9 20 20 20h175c11 0 20-9 20-20s-9-20-20-20H145c-11 0-20 9-20 20zM144 207h116c11 0 20-9 20-20s-9-20-20-20H144c-11 0-20 9-20 20s9 20 20 20zM370 327H145c-11 0-20 9-20 20s9 20 20 20h225c11 0 20-9 20-20s-9-20-20-20z"
      />
    </SvgIcon>
  );
}

export default ReportIcon;
