import { colors } from './colors.styles';

export const tableStyles = {
  MuiTable: {
    styleOverrides: {
      root: {},
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        // maxHeight: '67vh',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        // td: {
        //   color: `${colors.text.secondary}`,
        // },
        '&.MuiTableRow-hover:hover td, &.MuiTableRow-hover:hover p': {
          cursor: 'pointer',
          backgroundColor: `${colors.hover.default}`,
          color: `${colors.text.primary}`,
          fontFamily: 'fontBold',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        color: colors.text.secondary,
        padding: '0 16px',
        // textAlign: 'left',
        height: '50px',
      },
      head: {
        color: colors.text.primary,
        fontSize: '1rem',
        borderColor: '#4285F4',
        backgroundColor: '#ffffff',
        fontFamily: 'fontSemiBold',
      },
    },
  },
};
