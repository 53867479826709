/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';
// import { colors } from '../../theme/colors.styles';

function TutorStatsIcon({ color = '', ...props }) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20 20H0V17.7778H20V20ZM5.55556 0H1.11111V15.5556H5.55556V0ZM12.2222 3.33333H7.77778V15.5556H12.2222V3.33333ZM18.8889 7.77778H14.4444V15.5556H18.8889V7.77778Z"
        fill={color}
      />
    </SvgIcon>
  );
}

export default TutorStatsIcon;
