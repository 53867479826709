import { createSlice } from '@reduxjs/toolkit';
import { authenticationApi } from './api/authentication';

const token = localStorage.getItem('token');

function storeToken(value) {
  if (value && value.length > 0) {
    localStorage.setItem('token', value);
  } else {
    localStorage.removeItem('token');
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: { token },
  extraReducers: (builder) => {
    builder.addMatcher(
      authenticationApi.endpoints.login.matchFulfilled,
      (state, { payload: { jwttoken } }) => {
        state.token = jwttoken;
        storeToken(jwttoken);
      }
    );
  },
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
      storeToken(payload);
    },
  },
});

export const { setToken } = authSlice.actions;
export const getToken = (state) => state.auth.token;
