import CircleIcon from '@mui/icons-material/Circle';
import { Icon, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import IconTutor from '../../../assets/icons/IconTutor';
import { useGetDashboardTutorStatusQuery } from '../../../redux/slices/api/v1/tutors';
import { toServerDate } from '../../../shared/utils';
import { colors } from '../../../theme/colors.styles';
import { dashStyle } from '../dashboard.styles';
import CardContainer from './CardContainer';

const currentTimestamp = toServerDate();

function TutorStatus(props) {
  const { data, isLoading } = useGetDashboardTutorStatusQuery(currentTimestamp);
  const columns = [
    {
      id: 'subject',
      label: 'Subjects',
      align: 'left',
      Cell: (value, index) => (
        <Typography fontSize="0.75rem" fontFamily="fontSemiBold" color="#333333">
          {value}
        </Typography>
      ),
    },
    {
      id: 'active',
      label: '',
      color: 'success',
      align: 'center',
      Cell: (value, index) => (
        <Typography align="center" fontSize="0.75rem" fontFamily="fontMedium">
          {value}
        </Typography>
      ),
    },
    {
      id: 'inActive',
      label: '',
      color: 'disabled',
      align: 'center',
      Cell: (value, index) => (
        <Typography align="center" fontSize="0.75rem" fontFamily="fontMedium">
          {value}
        </Typography>
      ),
    },
    {
      id: 'busy',
      label: '',
      color: 'error',
      align: 'center',
      Cell: (value, index) => (
        <Typography align="center" fontSize="0.75rem" fontFamily="fontMedium">
          {value}
        </Typography>
      ),
    },
  ];

  return (
    <CardContainer
      icon={<IconTutor fontSize="24" color={colors.primary.main} />}
      title="Tutor Status"
      titleStyle={{ mb: 1 }}
    >
      <TableContainer sx={{ maxHeight: 320, width: props.width }}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  padding="none"
                  key={`head-${column.id}`}
                  style={{
                    minWidth: column.minWidth,
                    ...dashStyle.tableCellHead,
                  }}
                >
                  {index !== 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon color={column.color} sx={{ fontSize: 10 }} component={CircleIcon} />
                      {column.label}
                    </div>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={`row-${row.code}-${index}`}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      style={{
                        textAlign: column.align,
                        ...dashStyle.tableCellRoot,
                      }}
                    >
                      {column.Cell(value, index)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContainer>
  );
}

TutorStatus.propTypes = {};

export default TutorStatus;

const json = [
  {
    status: 'Inactive',
    count: 50,
    color: 'primary',
  },
  {
    status: 'Disconnected',
    count: 20,
    color: 'error',
  },
  {
    status: 'Unassigned',
    count: 70,
    color: 'success',
  },
];
