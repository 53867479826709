/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function FilterIcon(props) {
  return (
    <SvgIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.84629 6.65321V13.4612C4.8463 13.553 4.86978 13.6433 4.91451 13.7235C4.95924 13.8037 5.02374 13.8711 5.10187 13.9193C5.17999 13.9676 5.26916 13.995 5.36089 13.9991C5.45262 14.0032 5.54386 13.9837 5.62595 13.9426L8.85657 12.3273C8.9459 12.2825 9.021 12.2138 9.07349 12.1288C9.12599 12.0437 9.15379 11.9458 9.15379 11.8459V6.65321L13.878 0.880084C13.9229 0.825355 13.9565 0.762333 13.9769 0.694615C13.9974 0.626898 14.0044 0.55581 13.9974 0.485412C13.9904 0.415015 13.9696 0.346684 13.9362 0.284324C13.9027 0.221964 13.8574 0.166794 13.8027 0.121965C13.7479 0.0771353 13.6849 0.0435247 13.6172 0.0230515C13.5495 0.00257839 13.4784 -0.0043563 13.408 0.0026435C13.3376 0.00964329 13.2693 0.0304405 13.2069 0.0638476C13.1445 0.0972546 13.0894 0.142617 13.0445 0.197346L8.1986 6.12015C8.11981 6.21646 8.07681 6.33709 8.07692 6.46152V11.5131L5.92317 12.59V6.46152C5.92327 6.33709 5.88027 6.21646 5.80148 6.12015L1.6749 1.07715H10.2307C10.3735 1.07715 10.5104 1.02042 10.6114 0.919447C10.7124 0.818471 10.7691 0.681517 10.7691 0.538715C10.7691 0.395912 10.7124 0.258959 10.6114 0.157982C10.5104 0.057006 10.3735 0.000277831 10.2307 0.000277831H0.538797C0.436795 0.000209782 0.336871 0.029117 0.25066 0.0836338C0.164449 0.138151 0.0954972 0.216034 0.0518315 0.308217C0.00816579 0.4004 -0.0084171 0.503091 0.00401356 0.604332C0.0164442 0.705574 0.057377 0.801202 0.122046 0.880084L4.84629 6.65321Z"
        fill="black"
      />
    </SvgIcon>
  );
}

export default FilterIcon;
