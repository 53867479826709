import { intersection, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getUserPermissions, getUserRoles } from '../../../redux/slices/currentUser.slice';

export function useAuthority() {
  const roles = useSelector(getUserRoles);
  const permissions = useSelector(getUserPermissions);
  const hasRoles = (_roles) => {
    const uRoles = roles?.map((r) => r.role);
    return isEmpty(_roles) || intersection(uRoles, _roles)?.length > 0;
  };

  const hasPermissions = (per) => {
    const uPermissions = permissions?.map((r) => r.permission);
    return isEmpty(per) || intersection(uPermissions, per)?.length > 0;
  };

  const hasAuth = (_roles, per) => hasPermissions(per) && hasRoles(_roles);

  return { hasAuth, hasRoles, hasPermissions };
}
