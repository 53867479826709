/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function SessionHistoryIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16 9C17.49 9 18.87 9.47 20 10.26V6C20 4.89 19.11 4 18 4H14V2C14 0.89 13.11 0 12 0H8C6.89 0 6 0.89 6 2V4H2C0.89 4 0.00999999 4.89 0.00999999 6L0 17C0 18.11 0.89 19 2 19H9.68C9.17265 17.9335 8.94357 16.7559 9.01414 15.577C9.08471 14.3981 9.45262 13.2563 10.0836 12.2579C10.7145 11.2596 11.588 10.4373 12.6225 9.86758C13.6571 9.2979 14.819 8.99943 16 9ZM8 2H12V4H8V2Z" />
      <path d="M16 11C13.24 11 11 13.24 11 16C11 18.76 13.24 21 16 21C18.76 21 21 18.76 21 16C21 13.24 18.76 11 16 11ZM17.65 18.35L15.5 16.2V13H16.5V15.79L18.35 17.64L17.65 18.35Z" />
    </SvgIcon>
  );
}

export default SessionHistoryIcon;
