import { CircularProgress } from '@mui/material';
import classnames from 'classnames';
import React from 'react';

const overlayStyle = {
  justifyContent: 'center',
  position: 'absolute',
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  top: 0,
  alignItems: 'center',
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.1)',
  zIndex: 2,
};

const noOverLayStyle = {
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 2,
};

function Loader({ className, variant, size, hideText, text, overlay }) {
  return (
    <div
      style={overlay ? overlayStyle : noOverLayStyle}
      className={classnames('animate__animated animate__fadeIn pt-3 text-center', className)}
    >
      <CircularProgress variant={variant} size={size} />
      <h5 className="mt-2">{!hideText && (text || 'Loading')}</h5>
    </div>
  );
}

export default Loader;
