import {
  Avatar,
  Box,
  Grid,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CommentIcon from '../../../assets/icons/CommentIcon';
import FilterIcon from '../../../assets/icons/FilterIcon';
import TutorStatsIcon from '../../../assets/icons/TutorStatsIcon';
import { useGetTutorStatusByIdMutation } from '../../../redux/slices/api/v1/tutors';
import CanShow from '../../../shared/components/CanShow';
import DropdownSelect from '../../../shared/components/Dropdowns/DropdownSelect';
import Loader from '../../../shared/components/Loader';
import PageContainer from '../../../shared/components/PageContainer';
import PageHeader from '../../../shared/components/PageHeader';
import StarRating from '../../../shared/components/StarRating';
import StatusBadge from '../../../shared/components/StatusBadge';
import { getTimeFromMinute, toServerDate } from '../../../shared/utils';
import { formatName } from '../../../shared/utils/format';
import { colors } from '../../../theme/colors.styles';
import CardContainer from '../../Dashboard/components/CardContainer';

const dateOptions = [
  { id: 'today', label: 'Today' },
  { id: 'lastWeek', label: 'Last Week' },
  { id: 'lastMonth', label: 'Last Month' },
];
function TutorStats({ id }) {
  const [getTutorStatusById, { data, isLoading }] = useGetTutorStatusByIdMutation();
  const [filters, setFilters] = useState({
    tutorId: id,
    currentDateTimeInUTC: toServerDate(),
    filter: dateOptions[0]?.id,
  });

  useEffect(() => {
    getTutorStatusById(filters);
  }, [filters]);

  const handleFilters = (filter) => {
    setFilters({ ...filters, filter });
  };

  return (
    <PageContainer sx={{ minHeight: 380 }}>
      <Grid container>
        <Grid item xs={8}>
          <PageHeader
            icon={<TutorStatsIcon width="26" color={colors.success.main} />}
            title="Tutor Stats"
            containerStyle={{ px: 1, mx: 2, pb: 1.5, borderBottom: '1px solid #dcdcdc' }}
          >
            <DropdownSelect
              variant="standard"
              sx={{ color: 'primary.main', padding: '5px', fontFamily: 'fontSemiBold' }}
              value={filters?.filter}
              onChange={(v) => handleFilters(v.id)}
              options={dateOptions}
              startAdornment={
                <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                  <FilterIcon fontSize="10" color="dark" />
                  <Typography marginLeft={0.6} fontFamily="fontSemiBold">
                    Filter by
                  </Typography>
                </InputAdornment>
              }
            />
          </PageHeader>
          <CanShow show={!isLoading} fallbackComponent={<Loader />}>
            <ListItem>
              <ListItemAvatar>
                <Avatar alt={formatName(data)} />
              </ListItemAvatar>
              <Box>
                <Box display="flex" flexDirection="row">
                  <Typography fontFamily="fontSemiBold" sx={{ marginRight: 1 }}>
                    {formatName(data)}
                  </Typography>
                  <StatusBadge label={data?.status} />
                </Box>
                <Box maxHeight={60}>
                  <StarRating
                    size="small"
                    name={data?.rating?.toString()}
                    readOnly
                    value={data?.rating}
                  />
                </Box>
              </Box>
            </ListItem>
            <Box p={2}>
              <Box
                my={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  flexGrow: 1,
                }}
              >
                <ListItemText
                  primary="Total online hrs"
                  secondary={getTimeFromMinute(data?.onlineMinutes)}
                />
                <ListItemText
                  primary="Total session hrs"
                  secondary={getTimeFromMinute(data?.sessionHours)}
                />
                <ListItemText
                  primary="Idle time"
                  secondary={getTimeFromMinute(data?.idleTimeMinutes)}
                />
                <ListItemText primary="Session count" secondary={data?.sessionCount} />
                <ListItemText primary="Session rating" secondary={data?.sessionRating} />
              </Box>
              <Typography fontFamily="fontBold" variant="h6">
                Students Feedback
              </Typography>
              <Box
                my={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  flexGrow: 1,
                }}
              >
                <ListItemText
                  primary="First question start time"
                  secondary={getTimeFromMinute(data?.firstQuestionStartTime)}
                />
                <ListItemText
                  primary="Student wait time"
                  secondary={getTimeFromMinute(data?.studentWaitTime)}
                />
                <ListItemText
                  primary="Tutor response time"
                  secondary={getTimeFromMinute(data?.tutorResponseTime)}
                />
                <ListItemText primary="Quality score" secondary={`${data?.qualityScore} /10`} />
              </Box>
            </Box>
          </CanShow>
        </Grid>
        <Grid item xs={4} sx={{ pr: 2 }}>
          <CardContainer title="Top 10 Comments" icon={<CommentIcon size={24} />}>
            <Box sx={{ mx: '-15px', height: '290px', overflow: 'scroll' }}>
              {data?.commentRollOut?.map((com, index) => (
                <Box
                  padding={2}
                  key={index}
                  sx={{
                    backgroundColor: index % 2 !== 0 ? 'white' : colors.hover.default,
                  }}
                  container
                >
                  <Box item xs={12}>
                    <Typography color="#828282" alignItems="center">
                      &quot;{com.comments}&quot;
                    </Typography>
                  </Box>
                  <Grid container colSpacing={1}>
                    <Grid item xs={8} marginTop={1}>
                      <Typography fontFamily="fontExtraBold" fontSize={12}>
                        {formatName(com.student && com.student[0])}
                      </Typography>
                      <Typography fontSize={12}>{`School: ${com.school}`}</Typography>
                    </Grid>
                    <Grid item xs={4} marginTop={1}>
                      <Typography fontSize={12}>{`Ses ID: ${com.sessionId}`}</Typography>
                      <Typography fontSize={12}>{`Grade: ${com.grade}`}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          </CardContainer>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

TutorStats.propTypes = {
  id: PropTypes.number.isRequired,
};

function DisplayLabel({ label, value }) {
  return (
    <Box>
      <Typography fontFamily="fontSemiBold">{label}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
}
export default TutorStats;
