import React from 'react';

function NotFoundPage() {
  return (
    <div>
      <h2>Page Not found</h2>
    </div>
  );
}

export default NotFoundPage;
