/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function ILPIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M14 0H8V7L5.5 5.5L3 7V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0ZM10 10C10.3956 10 10.7822 10.1173 11.1111 10.3371C11.44 10.5568 11.6964 10.8692 11.8478 11.2346C11.9991 11.6001 12.0387 12.0022 11.9616 12.3902C11.8844 12.7781 11.6939 13.1345 11.4142 13.4142C11.1345 13.6939 10.7781 13.8844 10.3902 13.9616C10.0022 14.0387 9.60009 13.9991 9.23463 13.8478C8.86918 13.6964 8.55682 13.44 8.33706 13.1111C8.1173 12.7822 8 12.3956 8 12C8 11.4696 8.21071 10.9609 8.58579 10.5858C8.96086 10.2107 9.46957 10 10 10ZM14 18H6V17C6 15.67 8.67 15 10 15C11.33 15 14 15.67 14 17V18Z" />
    </SvgIcon>
  );
}

export default ILPIcon;
