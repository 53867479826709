import React from 'react';

export const DASHBOARD_PATH = '/';
export const NOTIFICATIONS_PATH = '/f/notifications';
export const SCHEDULE_CALENDAR_PATH = '/f/schedule-calendar';
export const SESSION_HISTORY_PATH = '/f/session_history';
export const UPCOMING_SESSION_PATH = '/f/upcoming-sessions';
export const ONGOING_SESSION_PATH = '/f/ongoing-sessions';
export const TUTOR_AVAILABILITY_PATH = '/f/tutor-availability';
export const DOT_ANALYSIS_PATH = '/f/dot-analysis';
export const COMMENTS_PATH = '/f/comments';
export const MANAGEMENTS_PATH = '/f/management';
export const TUTOR_TEAM_PATH = '/f/tutor-team';
export const ROLE_MANAGE_PATH = '/f/manage-roles';
export const PERMISSION_PATH = '/f/permissions';
export const LOGOUT_PATH = '/f/logout';
export const LOGIN_PATH = '/f/login';
export const PROFILE_PATH = '/f/profile';
export const TUTOR_SHIFT_PATH = '/f/ShiftTiming';
export const ADD_TIME_SLOT = '/f/AddTimeSlot';
export const REPORTS_PATH = '/f/reports';

const DashboardPage = React.lazy(() => import('../pages/Dashboard/Dashboard.page'));
const UpcomingSessionPage = React.lazy(() => import('../pages/UpcomingSession/page'));
const TutorAvailabilityPage = React.lazy(() => import('../pages/TutorAvailability/page'));
const NotificationPage = React.lazy(() => import('../pages/Notifications/page'));
const OngoingSessionPage = React.lazy(() => import('../pages/OngoingSession/page'));
const ScheduleCalendarPage = React.lazy(() => import('../pages/ScheduleCalendar/page'));
const ManagementPage = React.lazy(() => import('../pages/Management/page'));
const CommentsPage = React.lazy(() => import('../pages/Comments/page'));
const ProfilePage = React.lazy(() => import('../pages/Profile/page'));
// const sessionHistoryMenu = React.lazy(() => import('../pages/SessionHistory/page'));
const SessionHistory = React.lazy(() => import('../pages/TutorAvailability/SessionHistory.page'));

export const routes = [
  {
    path: DASHBOARD_PATH,
    name: 'Dashboard',
    component: DashboardPage,
    index: true,
    exact: true,
  },
  {
    path: NOTIFICATIONS_PATH,
    name: 'Notification',
    component: NotificationPage,
    exact: true,
  },
  {
    path: ONGOING_SESSION_PATH,
    name: 'Ongoing Sessions',
    component: OngoingSessionPage,
    exact: true,
  },

  {
    path: UPCOMING_SESSION_PATH,
    name: 'Upcoming Sessions',
    component: UpcomingSessionPage,
    exact: true,
  },
  {
    path: SESSION_HISTORY_PATH,
    name: 'Session History',
    component: SessionHistory,
    exact: true,
  },
  {
    path: SCHEDULE_CALENDAR_PATH,
    name: 'Schedule Calender',
    component: ScheduleCalendarPage,
    exact: true,
  },
  {
    path: TUTOR_AVAILABILITY_PATH,
    name: 'Tutor Availability',
    component: TutorAvailabilityPage,
    exact: true,
  },
  {
    path: MANAGEMENTS_PATH,
    name: 'Management',
    component: ManagementPage,
    exact: true,
  },
  {
    path: COMMENTS_PATH,
    name: 'Recent Comments',
    component: CommentsPage,
    exact: true,
  },
  {
    path: PROFILE_PATH,
    name: 'Profile',
    component: CommentsPage,
    exact: true,
  },
  {
    path: TUTOR_SHIFT_PATH,
    name: 'Tutor Shift',
    component: CommentsPage,
    exact: true,
  },
];
