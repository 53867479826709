import { Box } from '@mui/material';
import React from 'react';
import Loader from './Loader';

function PageContainer({ children, isLoading, sx, overflow = 'auto', ...props }) {
  return (
    <Box sx={{ position: 'relative', overflow, ...sx }} {...props}>
      {isLoading ? <Loader /> : children}
    </Box>
  );
}

export default React.memo(PageContainer);
