/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

function DashboardIcon(props) {
  return (
    <SvgIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H11C11.7956 0 12.5587 0.316071 13.1213 0.87868C13.6839 1.44129 14 2.20435 14 3V11C14 11.7956 13.6839 12.5587 13.1213 13.1213C12.5587 13.6839 11.7956 14 11 14H3C2.20435 14 1.44129 13.6839 0.87868 13.1213C0.316071 12.5587 0 11.7956 0 11V3ZM3 1C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V4.058L6.5 4.005V1H3ZM7.5 1V9H13V3C13 2.46957 12.7893 1.96086 12.4142 1.58579C12.0391 1.21071 11.5304 1 11 1H7.5ZM13 10H7.5V13H11C11.5304 13 12.0391 12.7893 12.4142 12.4142C12.7893 12.0391 13 11.5304 13 11V10ZM6.5 13V5.005L1 5.058V11C1 11.5304 1.21071 12.0391 1.58579 12.4142C1.96086 12.7893 2.46957 13 3 13H6.5Z" />
    </SvgIcon>
  );
}

export default DashboardIcon;
